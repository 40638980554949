let colors = ['red', 'yellow', 'green', 'blue', 'purple', 'orange', 'tomato', 'papaya', 'gold', 'cyan', 'darkblue', 'darkgreen', 'darkmagenta', 'darkorchid', 'darkred', 'darkgoldenrod', 'aliceblue', 'maroon', 'rebeccapurple'];

const display_loader = () => {
    let lines = document.getElementsByClassName('inner');
    Array.from(lines).forEach(e => {
        console.log("hello")
        e.style.borderColor = colors[Math.floor(colors.length * Math.random()) + 1];
    })
    let loader = document.getElementById('loader');
    let barrier = document.getElementById('barrier');
    loader.style.display = "block";
    barrier.style.display = "block";
}

const close_loader = () => {
    let loader = document.getElementById('loader');
    let barrier = document.getElementById('barrier');
    loader.style.display = "none";
    barrier.style.display = "none";
}

export {
    display_loader,
    close_loader
}
