import {getTodaysRez} from "./lamba-api";

const format_reservations = (data) => {
    let tables = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let todaysRezzies = data.reduce((a, b) => {
        if (b.seating === "1" || b.time === "6:00") {
            a.seatingOne.push(b);
        } else {
            a.seatingTwo.push(b);
        }

        return a;
    }, {
        seatingOne: [],
        seatingTwo: []
    })

    todaysRezzies.seatingOne = tables
        .filter((unknown) => {
            return !todaysRezzies.seatingOne
                .some((reservedRez) => reservedRez.table
                    // eslint-disable-next-line eqeqeq
                    .some((reservedTable) => reservedTable == unknown));
        })
        .map((table) => {
            return {
                table: [table],
                id: null
            };
        })
        .concat(todaysRezzies.seatingOne);

    todaysRezzies.seatingTwo = tables
        .filter((unknown) => {
            return !todaysRezzies.seatingTwo
                .some((reservedRez) => reservedRez.table
                    // eslint-disable-next-line eqeqeq
                    .some((reservedTable) => reservedTable == unknown));
        })
        .map((table) => {
            return {
                table: [table],
                id: null
            };
        })
        .concat(todaysRezzies.seatingTwo);

    Object.keys(todaysRezzies).forEach((e) => {
        todaysRezzies[e] = todaysRezzies[e].sort((a, b) => a.table[0] === "10" ? 1 : b.table[0] === "10" ? -1 : a.table[0] < b.table[0] ? -1 : a.table[0] > b.table[0] ? 1 : !a.table[0] && b.table[0] ? -1 : a.table[0] && !b.table[0] ? 1 : 0);
    });
    return todaysRezzies;
}

const fetch_reservations = async (date, setChart) => {
    return getTodaysRez(date)
        .then(data => {
            let items = data.data.body.response["Items"];
            let formatted = items.map((e) => {
                Object.keys(e).forEach((key1) => {
                    let newValue;
                    // @ts-ignore
                    Object.keys(e[key1]).forEach((key2) => {
                        // @ts-ignore
                        newValue = e[key1][key2];
                    });
                    // @ts-ignore
                    e[key1] = newValue;
                });
                if (!e.hasOwnProperty("notes")) {
                    e["notes"] = "";
                }
                e.table = e.table
                    .map((o) => Number.parseInt(o))
                    .sort((x, y) => x < y ? -1 : x > y ? 1 : 0)
                    .map((o) => o.toString());
                return e;
            });
            setChart(format_reservations(formatted));
            return {
                data: data,
                ok: true
            }
        })
        .catch(err => {
            console.log("Unknown Error", err);
            return {
                data: err,
                ok: false
            }
        })
}

export {
    format_reservations,
    fetch_reservations
}
