import React, {useContext, useEffect, useState} from 'react';
import Edit from "./Edit";
import EditContext from "../contexts/EditContext";
import ChartContext from "../contexts/ChartContext";
import confirmed from "../imgs/verified.svg"
import repeat from "../imgs/repeat.svg"


const Table = (props) => {

    const [chart, ] = useContext(ChartContext);

    const [name, setName] = useState(null);
    const [people, setPeople] = useState(null);
    const [tables, setTables] = useState(null);
    const [tableNumber, setTableNumber] = useState(null);
    const [edit, setEdit] = useContext(EditContext);
    const [thisEdit, setThisEdit] = useState(false);
    const [tableClass, setTableClass] = useState("");
    const [thisTable, setThisTable] = useState(null);
    const [trigger, setTrigger] = useState(false);
    const [delay, setDelay] = useState(Math.ceil(3000));

    let colors = ["red", "orange", "yellow", "green", "blue", "violet", "pink"]

    useEffect(() => {
        if (props.data.id !== null) {
            setThisTable(document.getElementById(props.data.seating + "table" + props.data.table[0]));
        } else {
            document.getElementById(props.seating + props.data.table[0]).style.backgroundColor = "#721e15"
        }
    }, [thisTable, chart])

    useEffect(() => {
        if (thisTable !== null) {
            if (props.data.id !== null) {
                let nextColor = colors[Math.floor(Math.random() * colors.length)]
                while (nextColor === thisTable.style.backgroundColor) {
                    nextColor = colors[Math.floor(Math.random() * colors.length)]
                }
                thisTable.style.backgroundColor = nextColor;
                setTimeout(() => {
                    setTrigger(!trigger);
                }, delay)    
            } else {
                thisTable.style.backgroundColor = "#721e15"
            }
        }
    }, [trigger, thisTable, chart])


    useEffect(() => {
        if (props.data.id !== null) {
            setName(props.data.name);
            setPeople(props.data.people);
            setTables(props.data.table);
        } else {
            setName("");
            setPeople("");
            setTables(props.data.table);
        }
        setTableNumber(props.data.table[0]);
    }, [chart])

    useEffect(() => {
        let string = "table ";
        if (props.data.id === null) {
            string += "table-open ";
        } else {
            string += "table-reserved ";
        }
        if (props.data.confirmed !== undefined) {
            // console.log(props.data.confirmed);
            if (props.data.confirmed === "true") {
                string += "table-confirmed "
            }
        }
        if (thisEdit) {
            string += "editing ";
        }
        // string += "table-confirmed"
        setTableClass(string);
    }, [chart, thisEdit])


    const openEditHandler = (e) => {
        e.stopPropagation();
        setEdit(!edit);
        setThisEdit(!edit);
        let editObj = {
            ...props.data,
            seating: props.seating
        }
        props.setEditData(editObj);
    }

    return (
        <div id={props.data.id !== null ? props.seating + "table" + props.data.table[0] : props.seating + props.data.table[0]} className={tableClass} onClick={(e) => openEditHandler(e)}>
            {/*<div className="table-info-1">*/}
            <div className="confirm-repeat-container">
                {
                    props.data?.confirmed === "true" ? <img className="confirmed" src={confirmed}/> : null
                }

                {
                    props.data?.repeat === "true" ? <img className="repeat" src={repeat}/> : null
                }
            </div>


            <span className="table-info-name">
                {props.data.id !== null ? props.data.notes === "" || props.data.notes === " " ? name : `${name}***` : name}
            </span>
                <span className="table-info-people">
                {people === null || people === "" ? "" : `${people}p`}
            </span>
            {/*</div>*/}
            <div className="table-info-2" >
                {
                    tables === null ? <span>{`T${tableNumber}`}</span> : tables.map((e, i) => {
                        return (
                            <span key={`Cherry-${i}`}>
                            {`T${e}`}
                            </span>
                        )
                    })
                }
            </div>
            {
                // thisEdit === true ? <Edit data={props.data} thisEdit={setThisEdit} seating={props.seating}/> : <></>
            }
        </div>
    )
}

export default Table;
