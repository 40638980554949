import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/app.css';
import './css/header.css';
import './css/chart.css';
import './css/date_controller.css';
import './css/table.css';
import './css/edit.css';
import './css/loader.css';
import './css/wait_list.css'
import './css/add_wait_list_entry.css'
import './css/wait_list_entry.css'
import './css/open-wait-list-entry.css'
import './css/login.css'
import './css/search.css'
import './css/notification.css'




import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
