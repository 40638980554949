const date_xcrementer = (date, action, setDate) => {
    let newDate = new Date(date);
    newDate.setMonth(date.getMonth());
    newDate.setDate(date.getDate());
    newDate.setFullYear(date.getFullYear());
    switch (action) {
        case "previous":
            newDate.setDate(newDate.getDate() - 1);
            break;
        case "next":
            newDate.setDate(newDate.getDate() + 1);
            break;
        default:
            break;
    }
    setDate(newDate);
};

const date_change_handler = (e, setDate) => {
    const newDateData = {
        month: Number.parseInt(e.target.value.substring(5, 7)),
        day:   Number.parseInt(e.target.value.substring(8, 10)),
        year:  Number.parseInt(e.target.value.substring(0, 4))
    };
    const newDate = new Date(e);
    newDate.setFullYear(newDateData.year);
    newDate.setMonth(newDateData.month - 1);
    newDate.setDate(newDateData.day);
    setDate(newDate);
};

export {
    date_xcrementer,
    date_change_handler
}
