import React, {useContext, useEffect, useState} from 'react';
import DateContext                              from "../contexts/DateContext";
import {date_fetch_formatter}                   from "../utils/date-format-utils";
import {deleteWaitEntry, putWaitEntry}          from "../utils/wait-list-api";
import LoadingContext                           from "../contexts/LoadingContext";
import ToggleContext                            from "../contexts/ToggleContext";
import {extractNumbers}                         from "../utils/misc-utils";
import {close_loader, display_loader}           from "../utils/loader-utils";
import ErrorContext                             from "../contexts/ErrorContext";

const AddWaitListEntry = (props) => {

    const [date] = useContext(DateContext);
    const [, ] = useContext(LoadingContext);
    const [] = useContext(ToggleContext);
    const [error, setError] = useContext(ErrorContext);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneFormatted, setPhoneFormatted] = useState("");
    const [seating, setSeating] = useState([]);
    const [notes, setNotes] = useState("");
    const [people, setPeople] = useState("");

    useEffect(() => {
        let formattedNumber = [];
        let extractedCharacters = extractNumbers(phone);
        for (let i = 0; i < extractedCharacters.length; i++) {
            if (i === 0) {
                formattedNumber.push("(");
            }
            if (i === 3) {
                formattedNumber.push(")");
                formattedNumber.push(" ");
            }
            if (i === 6) {
                formattedNumber.push("-");
            }
            if (i === 10) {
                formattedNumber.push("-");
            }
            formattedNumber.push(extractedCharacters[i]);
        }
        setPhoneFormatted(formattedNumber.join(""));
    }, [phone]);

    useEffect(() => {
        if (props.open) {
            let {name, phone, seating, notes, people} = props.data;
            setName(name);
            setPhone(phone === "0" ? "" : extractNumbers(phone));
            setSeating(seating);
            setNotes(notes === " " ? "" : notes);
            setPeople(people === "0" ? "" : people);
            let theseChecks = document.getElementsByClassName("wait-list-checks");
            Array.from(theseChecks).forEach(e => {
                if (seating.includes(e.value)) {
                    e.checked = true;
                }
            })
        }
    }, [])

    const onDelete = () => {
        display_loader();
        deleteWaitEntry(props.data.id).then(r => {
            props.setToggle(!props.toggle);
            props.closeOpen();
            if (r.data.status === String(401)) {
                setError({notification: true, error: true, message: "Security Error"})
            } else if (r.data.status === String(200)) {
                setError({notification: true, error: false})
            } else {
                setError({notification: true, error: true, message: "unknown error"})
            }
        })
            .catch(err => {
                setError({notification: true, error: true, message: "unknown error"})
            });
    }

    const onSave = () => {
        display_loader();

        let newObj = {
            name: name === "" ? "No Name" : name,
            phone: phone === "" ? "0" : extractNumbers(phone),
            notes: notes === "" ? " " : notes,
            people: people === "" ? "0" : people,
            seating: seating.length === 0 ? [" "] : seating,
            id: props.open ? props.data.id :  Date.now() + Math.random().toString(36).substring(2, 9),
            date: date_fetch_formatter(date),
        }

        putWaitEntry(newObj)
            .then(r => {
                if (props.open) {
                    props.closeOpen();
                }
                props.setToggle(!props.toggle);
                if (r.data.status === String(401)) {
                    setError({notification: true, error: true, message: "Security Error"})
                } else if (r.data.status === String(200)) {
                    setError({notification: true, error: false})
                } else {
                    setError({notification: true, error: true, message: "unknown error"})
                }
            })
            .catch(err => {
                close_loader();
                console.log(err);
                setError({notification: true, error: true, message: "unknown error"})
            });
    }

    const checkboxChangeHandler = (e) => {
        let newArr = [...seating];
        if (e.target.checked) {
            newArr.push(e.target.value);
        } else {
            newArr = newArr.filter(a => a !== e.target.value);
        }
        setSeating(newArr);
    }

    return (
        <div id="add-wait-list-entry">
            <input type="text" placeholder="name" value={name} onChange={(e) => setName(e.target.value)}/>
            <input type="tel" placeholder="phone" value={phoneFormatted} onChange={(e) => setPhone(e.target.value)}/>
            <input type="tel" placeholder="# of Guests" value={people} onChange={(e) => setPeople(e.target.value)}/>
            <textarea placeholder="notes" value={notes} onChange={(e) => setNotes(e.target.value)}/>
            <div id="wait-radio-container">
                <div className="wait-radio-label-container">
                <span>
                    Seating 1
                </span>
                    <input className="wait-list-checks" type="checkbox" name="seating" value="1"
                           onChange={(e) => checkboxChangeHandler(e)}/>
                </div>
                <div className="wait-radio-label-container">

                    <span>Seating 2</span>
                    <input className="wait-list-checks" type="checkbox" name="seating" value="2"
                           onChange={(e) => checkboxChangeHandler(e)}/>
                </div>
            </div>
            <div id="wait-list-entry-buttons">
                {props.open ? <button onClick={onDelete}>DELETE</button> : <></>}
                <button onClick={(e) => {
                    e.stopPropagation();
                    return props.open ? props.closeOpen() : props.setAddToList(false);
                }}>CANCEL</button>
                <button onClick={onSave}>SAVE</button>
            </div>
        </div>
    );
};

export default AddWaitListEntry;
