import React, {
	useContext,
	useState
}                       from 'react';
import triangle         from "../imgs/triangle.svg";
import WaitListToggle   from "../contexts/WaitListToggle";
import ChartContext     from "../contexts/ChartContext";
import CopierContext    from "../contexts/CopierContext";
import SearchContext    from "../contexts/SearchContext";
import InfoContext      from "../contexts/InfoContext";
import {display_loader} from "../utils/loader-utils";
import wait from "../imgs/wait.svg"

const FunctionSwitch = () => {
	
	const [waitList, setWaitList] = useContext(WaitListToggle);
	const [chart, ] = useContext(ChartContext);
	const [, setCopier] = useContext(CopierContext);
	const [, ] = useContext(SearchContext);
	const [info, setInfo] = useContext(InfoContext);
	const [toggleClass, setToggleClass] = useState("");
	const [hideClass, setHideClass] = useState("hide-functions");
	const [, ] = useState("");
	const [, ] = useState(false);
	
	
	// const copyAllNumbers = (e) => {
	// 	e.stopPropagation();
		
	// 	let string = "";
	// 	if (chart.seatingOne.length > 0) {
	// 		chart.seatingOne.forEach(a => {
	// 			if (a.confirmed === "false" || a.confirmed === undefined) {
	// 				if (a.phone !== undefined && a.phone.length > 1) {
	// 					if (a.phone.length === 10 || a.phone.length === 7) {
	// 						string += a.phone;
	// 						string += ",";
	// 					}
	// 				}
	// 			}
	// 		})
	// 	}
	// 	if (chart.seatingTwo.length > 0) {
	// 		chart.seatingTwo.forEach(a => {
	// 			if (a.confirmed === "false" || a.confirmed === undefined) {
	// 				if (a.phone !== undefined && a.phone.length > 1) {
	// 					if (a.phone.length === 10 || a.phone.length === 7) {
	// 						string += a.phone;
	// 						string += ",";
	// 					}
	// 				}
	// 			}
	// 		})
	// 	}
	// 	setCopier({
	// 				  active: true,
	// 				  data:   string
	// 			  })
		
	// 	toggleClassFunc()
	// }
	
	// const searchReservation = () => {
	// 	setSearch(true);
	// 	toggleClassFunc();
	// }
	
	// const toggleClassFunc = () => {
	// 	if (toggleClass === "") {
	// 		setToggleClass("function-switch-toggled");
	// 		setHideClass("");
	// 	} else {
	// 		setToggleClass("");
	// 		setHideClass("disappear-functions");
	// 	}
	// }
	
	const transitionEndHandler = () => {
		if (hideClass === "disappear-functions") {
			setHideClass("hide-functions");
		} else {
			setHideClass("");
		}
	}
	
	
	return (<div id="function-switch">
		<img className={toggleClass} src={wait} onClick={(e) => {
				// e.stopPropagation();
				// toggleClassFunc();
				setWaitList(!waitList)
			}} alt="triangle"/>
		{/* <div id="special-functions-container" className={hideClass} onTransitionEnd={() => transitionEndHandler()}>
			<button onClick={(e) => copyAllNumbers(e)}>COPY NUMS</button>
			<button onClick={(e) => {
				e.stopPropagation();
				toggleClassFunc();
				setWaitList(!waitList)
			}}>WAIT LIST
			</button>
			<button onClick={() => {
				display_loader();
				setInfo(true)
			}}>INFO</button>
		</div> */}
	</div>);
};

export default FunctionSwitch;
