const extractValues = (data1) => {
    let data = [...data1];
    data = data.map(b => {
        Object.keys(b).forEach((key1) => {
            let newValue;
            // @ts-ignore
            Object.keys(b[key1]).forEach((key2) => {
                // @ts-ignore
                newValue = b[key1][key2];
            });
            // @ts-ignore
            b[key1] = newValue;
        });
        return b;
    })
    data.sort((a, b) => {
        return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
    })
    return data;
}

export {
    extractValues
}
