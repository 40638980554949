import React, {useState} from 'react';
import AddWaitListEntry from "./AddWaitListEntry";

const WaitListEntry = (props) => {

    const [open, setOpen] = useState(false);

    const closeOpenHandler = () => {
        setOpen(false);
    }

    return (
        <div id="wait-entry-container" onClick={() => setOpen(true)}>

            {open ? <AddWaitListEntry toggle={props.toggle} setToggle={props.setToggle} data={props.data} open={true} closeOpen={closeOpenHandler}/> : (
                <>

                    <span className="wait-name-span">
                        {props.data.notes === "" || props.data.notes === " " ? props.data.name : `${props.data.name}***`}
                    </span>
                    <span className="wait-people-span">{props.data.people}p</span>
                    <div className="wait-seating-span">

                        {
                            props.data.seating.map((e, i) => {
                                if (e === " ") {
                                    // do nothing
                                } else {
                                    return (
                                        <span key={`spanny-${i}`}>
                         {` S${e }`}
                </span>
                                    )
                                }
                                return null;
                            })
                        }
                    </div>
                </>
            )}
        </div>
    );
};

export default WaitListEntry;
