import React, {
	useContext,
	useState
}                   from 'react';
import {
	close_loader,
	display_loader
}                   from "../utils/loader-utils";
import {login}      from "../utils/wait-list-api";
import LoginContext from "../contexts/LoginContext";

const Login = () => {
	
	const [, setAuthenticated] = useContext(LoginContext);
	
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);
	
	const passwordInputHandler = async (e) => {
		if (e.key === "Enter") {
			display_loader();
			setError(false);
			let encoded = btoa(encodeURI(password));
			setPassword("");
			login(encoded)
				.then(e => {
					if (e.body.success) {
						setAuthenticated(true);
					} else {
						setError(true);
						setTimeout(() => {
							close_loader()
						}, 250);
					}
				})
		}
	}

return (
	<div id="login-wrapper">
		<div id="login-container">
	<input type="password" placeholder="password" value={password}
		   onKeyDown={(e) => passwordInputHandler(e)}
		   onChange={(e) => setPassword(e.target.value)}/>
	{error ? <span>Error</span> : null}
		</div></div>);
}
;

export default Login;
