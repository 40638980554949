import React, {
	useContext,
	useEffect,
	useState
} from 'react';
import {wait_window_positioner} from "../utils/dom-manager";
import DateContext         from "../contexts/DateContext";
import AddWaitListEntry    from "./AddWaitListEntry";
import {getTodaysWaitList} from "../utils/wait-list-api";
import LoadingContext      from "../contexts/LoadingContext";
import WaitListEntry       from "./WaitListEntry";
import {extractValues}     from "../utils/wait-list-formatter";
import WaitListToggle      from "../contexts/WaitListToggle";
import {
	close_loader,
	display_loader
}                          from "../utils/loader-utils";
import ErrorContext        from "../contexts/ErrorContext";

const WaitList = () => {
	
	const [addToList, setAddToList] = useState(false);
	const [date] = useContext(DateContext);
	const [, ] = useContext(LoadingContext);
	const [, setWaitList] = useContext(WaitListToggle);
	const [, setError] = useContext(ErrorContext);
	const [toggle, setToggle] = useState(false);
	const [list, setList] = useState([]);
	
	
	useEffect(() => {
		display_loader();
		getTodaysWaitList(date)
			.then(r => {
				setAddToList(false);
				setList(extractValues(r.data.body.response["Items"]));
				setTimeout(() => {
					close_loader();
					
				}, 350);
				if (r.data.status === String(401)) {
					setError({notification: true, error: true, message: "Security Error"})
				} else if (r.data.status === String(200)) {
					setError({notification: true, error: false})
				} else {
					setError({notification: true, error: true, message: "unknown error"})
				}
			})
			.catch(err => {
				console.log(err);
				setError({notification: true, error: true, message: "unknown error"})
				setWaitList(false);
				setTimeout(() => {
					close_loader();
					
				}, 350);
			})
	}, [date, toggle])
	
	useEffect(() => {
		wait_window_positioner()
	}, [])
	
	return (<div id="wait-list-wrapper">
			<div id="wait-list-container">
				{list.length > 0 ? list.map((e, i) => {
					return (<WaitListEntry key={`entry-${i}`} data={e} toggle={toggle} setToggle={setToggle}/>)
				}) : <span>EMPTY</span>}
				<div id="wait-list-buttons-container">
					
					<button onClick={() => setAddToList(!addToList)}>
						{addToList ? "CANCEL" : "ADD"}
					</button>
					<button onClick={() => setWaitList(false)}>CLOSE</button>
				</div>
				{addToList ?
					<AddWaitListEntry setAddToList={setAddToList} toggle={toggle} setToggle={setToggle}/> : <></>}
			</div>
		</div>);
};

export default WaitList;
