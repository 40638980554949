import {date_fetch_formatter} from "./date-format-utils";
import {getCookie}            from "./cookies";
// import {data} from '../data/old_data';

// TODO
//  validate input data

let putRez = async (reservation) => {
    let token = btoa(encodeURI(getCookie('lola-sesh')));
    return await fetch("https://fm6wivz7qcpe3batatlu627iri0lmnbw.lambda-url.us-east-1.on.aws/",
        {
            method: "post",
            body: JSON.stringify(reservation),
            mode: "cors",
            credentials: "include",
            headers: {
                "authorization": `Bearer ${token}`,
                "lola-action": "put-rez",
                "content-type": "text/plain",
            }
        })
        .then((r) => r.json())
        .then((json) => {
            return {
                ok: true,
                data: json
            };
        })
        .catch((err) => {
            console.log(err)
            return {
                ok: false,
                data: err
            };
        });
};

const getTodaysRez = async (date) => {
    let formattedDate = date_fetch_formatter(date);
    let token = btoa(encodeURI(getCookie('lola-sesh')));


    // let theData = data.map(e => {
    //     if (e.hasOwnProperty("time")) {
    //         e['seating'] = e['time'];
    //     }


    //     if (e.hasOwnProperty("notes") === false) {
    //         e['notes'] = " ";
    //     }

    //     if (e.hasOwnProperty("confirmed") === false) {
    //         e['confirmed'] = "false";
    //     }

    //     if (e.hasOwnProperty("repeat") === false) {
    //         e['repeat'] = "true";
    //     }

    //     return e;
    // })

    return await fetch("https://fm6wivz7qcpe3batatlu627iri0lmnbw.lambda-url.us-east-1.on.aws/",
        {
            method: "get",
            mode: "cors",
            credentials: "include",
            headers: {
                "authorization": `Bearer ${token}`,
                "reservation-date": formattedDate,
                "lola-action": "get-rez",
                "content-type": "text/plain",
            }
        })
        .then(async (r) => {
                // // @ts-ignore
                // let reader: ReadableStreamDefaultReader<object> = r.body.getReader();
                // while (true) {
                // 	let res = await reader.read();
                // 	if (res.done) {
                // 		break;
                // 	} else {
                // 		// @ts-ignore
                // 		console.log(new TextDecoder("utf-8").decode(res.value));
                // 	}
                // }

                return r.json();
            }
        )
        .then((json) => {
            return {
                ok: true,
                data: json
            };
        })
        .catch((err) => {
            console.log(err)
            return {
                ok: false,
                data: err
            };
        });
};

const getAppVersion = async () => {
    let token = btoa(encodeURI(getCookie('lola-sesh')));
    return await fetch("https://fm6wivz7qcpe3batatlu627iri0lmnbw.lambda-url.us-east-1.on.aws/",
                       {
                           method: "get",
                           mode: "cors",
                           credentials: "include",
                           headers: {
                               "authorization": `Bearer ${token}`,
                               "lola-action": "get-version",
                               "content-type": "text/plain",
                           }
                       })
        .then((r) => {
                  return r.json();
              }
        )
        .then((json) => {
            return {
                ok: true,
                data: json
            };
        })
        .catch((err) => {
            console.log(err)
            return {
                ok: false,
                data: err
            };
        });
};

const deleteRez = async (id, date) => {
    let formattedDate = date_fetch_formatter(date);
    let token = btoa(encodeURI(getCookie('lola-sesh')));
    return await fetch("https://fm6wivz7qcpe3batatlu627iri0lmnbw.lambda-url.us-east-1.on.aws/",
        {
            method: "delete",
            mode: "cors",
            credentials: "include",
            headers: {
                "authorization": `Bearer ${token}`,
                "lola-action": "delete-rez",
                "reservation-id": id,
                "reservation-date": formattedDate,
                "content-type": "text/plain",
            }
        })
        .then(async (r) => {
            // // @ts-ignore
            // let reader: ReadableStreamDefaultReader<object> = r.body.getReader();
            // while (true) {
            // 	let res = await reader.read();
            // 	if (res.done) {
            // 		break;
            // 	} else {
            // 		// @ts-ignore
            // 		console.log(new TextDecoder("utf-8").decode(res.value));
            // 	}
            // }
            return r.json();
        })
        .then((json) => {
            return {
                ok: true,
                data: json
            };
        })
        .catch((err) => {
            console.log(err)
            return {
                ok: false,
                data: err
            };
        });
};

export {
    putRez,
    getTodaysRez,
    deleteRez,
    getAppVersion
};
