import React, {
	useContext,
	useEffect,
	useState
}                   from 'react';
import ErrorContext from "../contexts/ErrorContext";

const Notification = (props) => {
	const [error, setError] = useContext(ErrorContext);
	
	const [thisClass, setThisClass] = useState(props.error ? "notification-error" : "notification-success");
	
	useEffect(() => {
		document.getElementById('notification-window').setAttribute('data-animation', 'true');
	}, [])
	
	return (
		<div id="notification-window" className={thisClass} data-animation="false" onAnimationEnd={(e) => setError({notification: false})}>
			<span>
				{props.error ? "ERROR" : "SUCCESS"}
			</span>
			{
				props.error ? <span>{props.message}</span> : null
			}
		</div>);
};

export default Notification;
