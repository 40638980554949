const body_resizer = () => {
    // const w = `${Math.max(document.documentElement.clientWidth, window.innerWidth || 0)}px`;
    // const h = `${Math.max(document.documentElement.clientHeight, window.innerHeight || 0)}px`;

    const w = window.innerWidth + "px"
    const h = window.innerHeight + "px"

    console.log(w, h);

    console.log(h);

    let body = document.body;
    body.style.minHeight = h;
    body.style.maxHeight = h;
    body.style.minWidth = w;
    body.style.maxWidth = w;

    // @ts-ignore
    let root  = document.getElementById("root");
    root.style.minHeight = h;
    root.style.maxHeight = h;
    root.style.minWidth = w;
    root.style.maxWidth = w;
    
    let appContainer  = document.getElementById("app-container");
    appContainer.style.minHeight = h;
    appContainer.style.maxHeight = h;
    appContainer.style.minWidth = w;
    appContainer.style.maxWidth = w;
};

const chart_repositioner = () => {
    body_resizer();
    let header= document.getElementById('header-container');
    let chart = document.getElementById('chart-container');
    let body = document.body;
    let headerHeight = header.offsetHeight;
    let bodyHeight = body.offsetHeight;
    chart.style.minHeight = `${bodyHeight - headerHeight - 95}px`;
    chart.style.maxHeight = `${bodyHeight - headerHeight - 95}px`;

    
    if (document.getElementById('edit-wrapper')) {
        edit_window_positioner();
    } else if (document.getElementById('wait-list-wrapper')) {
        wait_window_positioner();
    }
}

const login_wrapper_sizer = () => {
    let appContainer  = document.getElementById("app-container");
    let loginWrapper  = document.getElementById("login-wrapper");
    
    loginWrapper.style.minHeight = appContainer.offsetHeight + "px";
    loginWrapper.style.maxHeight = appContainer.offsetHeight + "px";
    loginWrapper.style.minWidth = appContainer.style.minWidth;
    loginWrapper.style.maxWidth = appContainer.style.maxWidth;
}

const edit_window_positioner = () => {

    let body = document.body;
    let editWrapper = document.getElementById('edit-wrapper');
    let editWindow = document.getElementById('edit-window');

    editWrapper.style.minHeight = body.offsetHeight + "px";
    editWrapper.style.maxHeight = body.offsetHeight + "px";
    editWrapper.style.minWidth = body.style.minWidth;
    editWrapper.style.maxWidth = body.style.maxWidth;

}

const wait_window_positioner = () => {

    let body = document.body;
    let waitListWrapper = document.getElementById('wait-list-wrapper');
    waitListWrapper.style.minHeight = body.offsetHeight - 145 + "px";
    waitListWrapper.style.maxHeight = body.offsetHeight - 145 + "px";
    waitListWrapper.style.minWidth = body.style.minWidth;
    waitListWrapper.style.maxWidth = body.style.maxWidth;
}

const reposition_date_controller = () => {
    // let header= document.getElementById('title');
    // let chart = document.getElementById('chart-container');
    // let dateController = document.getElementById("date-controller");
    // let chartHeight = chart.offsetHeight;
    // let headerHeight = header.offsetHeight;

}

export {
    body_resizer,
    chart_repositioner,
    edit_window_positioner,
    wait_window_positioner,
    reposition_date_controller,
    login_wrapper_sizer
}
