import {date_fetch_formatter} from "./date-format-utils";
import {getCookie}            from "./cookies";

const putWaitEntry = async (entry) => {
	let token = btoa(encodeURI(getCookie('lola-sesh')));
	return await fetch("https://fm6wivz7qcpe3batatlu627iri0lmnbw.lambda-url.us-east-1.on.aws/",
					   {
						   method:      "post",
						   body:        JSON.stringify(entry),
						   mode:        "cors",
						   credentials: "include",
						   headers:     {
							   "authorization": `Bearer ${token}`,
							   "lola-action":  "put-wait",
							   "content-type": "text/plain",
						   }
					   })
		.then((r) => r.json())
		.then((json) => {
			return {
				ok:   true,
				data: json
			};
		})
		.catch((err) => {
			console.log(err)
			return {
				ok:   false,
				data: err
			};
		});
}

const getTodaysWaitList = async (date) => {
	let formattedDate = date_fetch_formatter(date);
	let token = btoa(encodeURI(getCookie('lola-sesh')));
	return await fetch("https://fm6wivz7qcpe3batatlu627iri0lmnbw.lambda-url.us-east-1.on.aws/",
					   {
						   method:      "get",
						   mode:        "cors",
						   credentials: "include",
						   headers:     {
							   "authorization": `Bearer ${token}`,
							   "lola-action":      "get-wait",
							   "reservation-date": formattedDate,
							   "content-type":     "text/plain",
						   }
					   })
		.then((r) => {
			// // @ts-ignore
			// let reader: ReadableStreamDefaultReader<object> = r.body.getReader();
			// while (true) {
			// 	let res = await reader.read();
			// 	if (res.done) {
			// 		break;
			// 	} else {
			// 		// @ts-ignore
			// 		console.log(new TextDecoder("utf-8").decode(res.value));
			// 	}
			// }
			
			return r.json();
		})
		.then((json) => {
			return {
				ok:   true,
				data: json
			};
		})
		.catch((err) => {
			console.log(err)
			return {
				ok:   false,
				data: err
			};
		});
};

const verifyToken = async () => {
	let token = btoa(encodeURI(getCookie('lola-sesh')));
	return await fetch("https://fm6wivz7qcpe3batatlu627iri0lmnbw.lambda-url.us-east-1.on.aws/",
					   {
						   method:      "get",
						   mode:        "cors",
						   credentials: "include",
						   headers:     {
							   "authorization": `Bearer ${token}`,
							   "lola-action":      "authorize",
							   "content-type":     "text/plain",
						   }
					   })
		.then((r) => {
			return r.json();
		})
		.then((json) => {
			return {
				ok:   true,
				data: json
			};
		})
		.catch((err) => {
			console.log(err)
			return {
				ok:   false,
				data: err
			};
		});
}

const login = async (encoded) => {
		return await fetch("https://fm6wivz7qcpe3batatlu627iri0lmnbw.lambda-url.us-east-1.on.aws/",
					   {
						   method:      "post",
						   mode:        "cors",
						   credentials: "include",
						   headers:     {
							   "authorization": `Basic ${encoded}`,
							   "lola-action":  "login",
							   "content-type": "text/plain",
						   }
					   })
		.then(async (r) => {
			return r.json();
		})
		.then((res) => {
			if (res.body.success) {
				let now = new Date();
				now.setDate(now.getDate() + 1);
				document.cookie = `lola-sesh=${res.body['token']};expires=${now.toUTCString()}`;
			} else {
				document.cookie = "lola-sesh=null";
			}
			return res;
		})
		.catch((err) => {
			console.log(err)
			return err;
		});
}

const deleteWaitEntry = async (id) => {
	let token = btoa(encodeURI(getCookie('lola-sesh')));
	return await fetch("https://fm6wivz7qcpe3batatlu627iri0lmnbw.lambda-url.us-east-1.on.aws/",
					   {
						   method:      "delete",
						   mode:        "cors",
						   credentials: "include",
						   headers:     {
							   "authorization": `Bearer ${token}`,
							   "lola-action":  "delete-wait",
							   "wait-id":      id,
							   "content-type": "text/plain",
						   }
					   })
		.then(async (r) => {
			// // @ts-ignore
			// let reader: ReadableStreamDefaultReader<object> = r.body.getReader();
			// while (true) {
			// 	let res = await reader.read();
			// 	if (res.done) {
			// 		break;
			// 	} else {
			// 		// @ts-ignore
			// 		console.log(new TextDecoder("utf-8").decode(res.value));
			// 	}
			// }
			
			return r.json();
		})
		.then((json) => {
			return {
				ok:   true,
				data: json
			};
		})
		.catch((err) => {
			console.log(err)
			return {
				ok:   false,
				data: err
			};
		});
};

export {
	putWaitEntry,
	getTodaysWaitList,
	deleteWaitEntry,
	login,
	verifyToken,
}
