const date_input_formatter = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`;
};

const date_fetch_formatter = (date) => {
    return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${(date.getDate()).toString().padStart(2, "0")}/${date.getFullYear()}`;
};
export {
    date_input_formatter,
    date_fetch_formatter
};
