import React from 'react';

const Header = () => {

    return (
        <div id="header-container">
            <span id="title">
            Lola's
            </span>
            <div id="seating-title-container">
                <span className="seating-title">Seating 1</span>
                <span className="seating-title">Seating 2</span>
            </div>
        </div>
    );
};

export default Header;
