import React from 'react';
let colors = ['red', 'yellow', 'green', 'blue', 'purple', 'orange', 'tomato', 'papaya', 'gold', 'cyan', 'darkblue', 'darkgreen', 'darkmagenta', 'darkorchid', 'darkred', 'darkgoldenrod', 'aliceblue', 'maroon', 'rebeccapurple'];


const Loader = () => {
    return (
        <div id="barrier" onClick={(e) => e.stopPropagation()}>
            <div id="loader">
                <div className="inner one" style={{borderColor: colors[Math.floor(colors.length * Math.random()) + 1]}}></div>
                <div className="inner two" style={{borderColor: colors[Math.floor(colors.length * Math.random()) + 1]}}></div>
                <div className="inner three" style={{borderColor: colors[Math.floor(colors.length * Math.random()) + 1]}}></div>
            </div>
        </div>
    );
};

export default Loader;
